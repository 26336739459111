const warn = () =>
  console.warn(
    'window.gtag is not defined. This could mean your google analytics script has not loaded on the page yet.',
  );

const createItem = ({
  sku,
  name,
  price,
  quantity,
}: {
  sku: string;
  name?: string;
  price: string | null | undefined;
  quantity?: number;
}): Gtag.Item => {
  return {
    item_id: sku,

    item_name: name,
    price: price?.replace(',', '.'),
    quantity,
  };
};

const pageview = (url: string, trackingId: string) => {
  if (!window.gtag) {
    return warn();
  }
  window.gtag('config', trackingId, {
    page_path: url,
  });
};

const event = ({ action, category, label, value }: Record<string, string>) => {
  if (!window.gtag) {
    return warn();
  }

  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};

const addToCart = ({
  sku,
  name,
  price,
  quantity,
}: {
  sku: string;
  name: string;
  price: string | null | undefined;
  quantity?: number;
}) => {
  if (!window.gtag) {
    return warn();
  }

  window.gtag('event', 'add_to_cart', {
    items: [createItem({ sku, name, price, quantity })],
  });
};

const removeFromCart = ({
  sku,
  name,
  price,
  quantity,
}: {
  sku: string;
  name: string;
  price: string | null | undefined;
  quantity?: number;
}) => {
  if (!window.gtag) {
    return warn();
  }

  window.gtag('event', 'remove_from_cart', {
    items: [createItem({ sku, name, price, quantity })],
  });
};

const beginCheckout = (cart: Gtag.EventParams) => {
  if (!window.gtag) {
    return warn();
  }

  window.gtag('event', 'begin_checkout', cart);
};

const setCheckoutStep = ({
  step,
  cart,
  event,
}: {
  step: number;
  event?: string;
  cart?: Gtag.EventParams;
}) => {
  if (!window.gtag) {
    return warn();
  }

  if (event && cart) {
    cart.checkout_step = step;
    window.gtag('event', event, cart);
  }
};

export const gtagClient = {
  pageview,
  event,
  addToCart,
  removeFromCart,
  beginCheckout,
  setCheckoutStep,
};
